<template>
  <v-footer dark padless v-if="businessData">
    <v-container class="my-4">
      <v-row>
        <v-col
          cols="12"
          sm="12"
          md="12"
          lg="8"
          xl="8"
          :class="{
            'text-center': !$vuetify.breakpoint.lgAndUp,
            'pb-0': $vuetify.breakpoint.lgAndUp
          }"
        >
          <h4 class="text-h5 mb-1 text-uppercase">{{ businessData.name }}</h4>
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="6" xl="6">
              <div>
                <p class="mb-1 text-subtitle-1">Contacto</p>
                <v-btn
                  outlined
                  class="text-subtitle-2 text-lowercase"
                  :href="`mailto:${businessData.mail}`"
                  ><v-icon size="18" class="mr-1">email</v-icon>
                  {{ businessData.mail }}</v-btn
                >
              </div>
              <div class="mt-2">
                <v-btn
                  outlined
                  class="text-subtitle-2"
                  :href="`tel:+52${businessData.phone}`"
                  ><v-icon size="18" class="mr-1">phone</v-icon>
                  {{ businessData.phone }}</v-btn
                >
              </div>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="6" xl="6">
              <div
                :class="{ 'text-center': !$vuetify.breakpoint.lgAndUp }"
                v-if="businessData.social_networks"
              >
                <p
                  class="mb-1 text-subtitle-1"
                  v-if="
                    businessData.social_networks.facebook ||
                      businessData.social_networks.instagram ||
                      businessData.social_networks.twitter
                  "
                >
                  Redes sociales
                </p>
                <p
                  class="ma-0 text-subtitle-2"
                  v-if="businessData.social_networks.facebook"
                >
                  <a
                    :href="
                      `https://www.facebook.com/${businessData.social_networks.facebook}`
                    "
                    target="_blank"
                    class="text-decoration-none white--text"
                  >
                    <img
                      src="@/assets/social-net/facebook-f-brands.svg"
                      width="13px"
                      height="13px"
                      alt="facebook"
                    />
                    {{ businessData.social_networks.facebook }}
                  </a>
                </p>
                <p
                  class="ma-0 text-subtitle-2"
                  v-if="businessData.social_networks.instagram"
                >
                  <a
                    :href="
                      `https://www.instagram.com/${businessData.social_networks.instagram}`
                    "
                    target="_blank"
                    class="text-decoration-none white--text"
                  >
                    <img
                      src="@/assets/social-net/instagram-brands.svg"
                      width="16px"
                      height="14px"
                      style="margin-bottom: -3.3px;"
                      alt="instagram"
                    />
                    {{ businessData.social_networks.instagram }}
                  </a>
                </p>
                <p
                  class="ma-0 text-subtitle-2"
                  v-if="businessData.social_networks.twitter"
                >
                  <a
                    :href="
                      `https://twitter.com/${businessData.social_networks.twitter}`
                    "
                    target="_blank"
                    class="text-decoration-none white--text"
                  >
                    <img
                      src="@/assets/social-net/twitter-brands.svg"
                      width="13px"
                      height="13px"
                      alt="twitter"
                    />
                    {{ businessData.social_networks.twitter }}
                  </a>
                </p>
              </div>
            </v-col>
          </v-row>
        </v-col>

        <v-col
          cols="12"
          sm="12"
          md="6"
          lg="4"
          xl="4"
          v-if="$vuetify.breakpoint.lgAndUp"
        >
          <p class="ma-0 text-subtitle-1">Mapa</p>
          <AppBusinessMapFooter
            :loadingBusinessData="loadingBusinessData"
            :businessData="businessData"
          />
        </v-col>
        <v-col cols="12" class="text-center caption"
          >power by <span class="text font-weight-bold">SSENCIAL</span></v-col
        >
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import BusinessMapFooter from "@/components/maps/BusinessMapFooter";
export default {
  components: {
    AppBusinessMapFooter: BusinessMapFooter
  },
  props: {
    businessData: {
      type: Object
    },
    loadingBusinessData: {
      type: Boolean
    }
  }
};
</script>

<style></style>
